import React from "react";
import { isMobileOnly } from "react-device-detect";
import ReactDOM from "react-dom";
import loadable from "@loadable/component";
import * as serviceWorker from "./serviceWorker";
import './index.css'

export function LazyLoaderIndex() {
  return (
    <div className="blocker">
      <div className="lds-dual-ring"></div>
      <div className="message">Loading...</div>
    </div>
  );
}
const DesktopAppLazy = loadable(() => import(/* webpackChunkName: "DesktopApp" */"./App"), {
  fallback: <LazyLoaderIndex />,
});

const MobileAppLazy = loadable(() => import(/* webpackChunkName: "MobileApp" */"./MobileApp"), {
  fallback: <LazyLoaderIndex />,
});

ReactDOM.render(
  <React.StrictMode>
    {/* comment this if you want to open in mobile too */}
    {
      isMobileOnly &&
      <>
        <MobileAppLazy />
      </>
    }
    {
      !isMobileOnly &&
      <DesktopAppLazy />
    }
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.unregister();
